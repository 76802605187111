import { Container } from 'semantic-ui-react';
import HankHeader from './components/HankHeader';
import HankFooter from './components/HankFooter';
import HankTabs from './components/HankTabs';
import './App.css';

import { useEffect } from 'react';

function App() {
  useEffect(() => {
    const setVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVH();
    window.addEventListener('resize', setVH);
    
    return () => window.removeEventListener('resize', setVH);
  }, []);

  return (
    <div className="App">
      <Container style={{ minHeight: 'calc(var(--vh, 1vh) * 100)' }}>
        <HankHeader />
        <HankTabs />
        <HankFooter />
      </Container>
    </div>
  );
}


export default App;
