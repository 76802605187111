import React from 'react'
import { Tab } from 'semantic-ui-react'
import PhotosTab from '../tabs/PhotosTab'
import VideoTab from '../tabs/VideoTab'
import FavoritesTab from '../tabs/FavoritesTab'
import WalkTab from '../tabs/WalkTab'
import HankTab from "../tabs/HankTab";

const panes = [
  { menuItem: { key: 'Hank', icon: "paw"}, render: () => <HankTab/> },
  { menuItem: { key: 'Photos', icon: "photo"}, render: () => <PhotosTab/> },
  { menuItem: { key: 'Favorites', icon: 'favorite'}, render: () => <FavoritesTab/> },
  { menuItem: { key: 'Music Video', icon: 'music'}, render: () => <VideoTab/> },
  { menuItem: { key: 'Walks', icon: 'calendar alternate outline'}, render: () => <WalkTab/> },
]

const HankTabs = () => <Tab panes={panes} />

export default HankTabs