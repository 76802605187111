import ImageGallery from "react-image-gallery";
import { Tab } from "semantic-ui-react";

import Hank01 from "../gallery/Hank01.jpg";
import THank01 from "../gallery/thumb/Hank01.jpg";
import Hank02 from "../gallery/Hank02.jpg";
import THank02 from "../gallery/thumb/Hank02.jpg";
import Hank03 from "../gallery/Hank03.jpg";
import THank03 from "../gallery/thumb/Hank03.jpg";
import Hank04 from "../gallery/Hank04.jpg";
import THank04 from "../gallery/thumb/Hank04.jpg";
import Hank05 from "../gallery/Hank05.jpg";
import THank05 from "../gallery/thumb/Hank05.jpg";
import Hank06 from "../gallery/Hank06.jpg";
import THank06 from "../gallery/thumb/Hank06.jpg";
import Hank07 from "../gallery/Hank07.jpg";
import THank07 from "../gallery/thumb/Hank07.jpg";
import Hank08 from "../gallery/Hank08.jpg";
import THank08 from "../gallery/thumb/Hank08.jpg";
import Hank09 from "../gallery/Hank09.jpg";
import THank09 from "../gallery/thumb/Hank09.jpg";
import Hank10 from "../gallery/Hank10.jpg";
import THank10 from "../gallery/thumb/Hank10.jpg";
import Hank11 from "../gallery/Hank11.jpg";
import THank11 from "../gallery/thumb/Hank11.jpg";
import Hank12 from "../gallery/Hank12.jpg";
import THank12 from "../gallery/thumb/Hank12.jpg";
import Hank13 from "../gallery/Hank13.jpg";
import THank13 from "../gallery/thumb/Hank13.jpg";
import Hank14 from "../gallery/Hank14.jpg";
import THank14 from "../gallery/thumb/Hank14.jpg";
import Hank15 from "../gallery/Hank15.jpg";
import THank15 from "../gallery/thumb/Hank15.jpg";
import Hank16 from "../gallery/Hank16.jpg";
import THank16 from "../gallery/thumb/Hank16.jpg";
import Hank17 from "../gallery/Hank17.jpg";
import THank17 from "../gallery/thumb/Hank17.jpg";
import Hank18 from "../gallery/Hank18.jpg";
import THank18 from "../gallery/thumb/Hank18.jpg";
import Hank19 from "../gallery/Hank19.jpg";
import THank19 from "../gallery/thumb/Hank19.jpg";
import Hank20 from "../gallery/Hank20.jpg";
import THank20 from "../gallery/thumb/Hank20.jpg";
import Hank21 from "../gallery/Hank21.jpg";
import THank21 from "../gallery/thumb/Hank21.jpg";
import Hank22 from "../gallery/Hank22.jpg";
import THank22 from "../gallery/thumb/Hank22.jpg";
import Hank23 from "../gallery/Hank23.jpg";
import THank23 from "../gallery/thumb/Hank23.jpg";
import Hank24 from "../gallery/Hank24.jpg";
import THank24 from "../gallery/thumb/Hank24.jpg";
//import Hank25 from "../gallery/Hank25.jpg";
//import THank25 from "../gallery/thumb/Hank25.jpg";
import Hank26 from "../gallery/Hank26.jpg";
import THank26 from "../gallery/thumb/Hank26.jpg";
import Hank27 from "../gallery/Hank27.jpg";
import THank27 from "../gallery/thumb/Hank27.jpg";
import Hank28 from "../gallery/Hank28.jpg";
import THank28 from "../gallery/thumb/Hank28.jpg";

import "./PhotosTab.css"

const images = [
    {
      original: Hank01,
      thumbnail: THank01,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank02,
      thumbnail: THank02,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank03,
      thumbnail: THank03,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank04,
      thumbnail: THank04,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank05,
      thumbnail: THank05,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank06,
      thumbnail: THank06,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank07,
      thumbnail: THank07,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank08,
      thumbnail: THank08,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank09,
      thumbnail: THank09,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank10,
      thumbnail: THank10,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank11,
      thumbnail: THank11,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank12,
      thumbnail: THank12,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank13,
      thumbnail: THank13,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank14,
      thumbnail: THank14,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank15,
      thumbnail: THank15,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank16,
      thumbnail: THank16,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank17,
      thumbnail: THank17,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank18,
      thumbnail: THank18,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank19,
      thumbnail: THank19,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank20,
      thumbnail: THank20,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank21,
      thumbnail: THank21,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank22,
      thumbnail: THank22,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank23,
      thumbnail: THank23,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank24,
      thumbnail: THank24,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank26,
      thumbnail: THank26,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank27,
      thumbnail: THank27,
      thumbnailClass: "constrained-thumbnail",
    },
    {
      original: Hank28,
      thumbnail: THank28,
      thumbnailClass: "constrained-thumbnail",
    }
];
  
const PhotosTab = () => {
    return (
        <Tab.Pane>
            <div className="photostab"> 
                <ImageGallery items={images}  />
            </div>
        </Tab.Pane>
    );
};

export default PhotosTab;
