import React from 'react'
import { Header } from 'semantic-ui-react'

const HankHeader = () => (
    <>
        <Header size='huge'>Just Say Juke Box Hero</Header>
        <Header size='large'>HANK is a very good boy</Header>
        <Header size='large'>Bernese Mountain Dog</Header>
    </>
)

export default HankHeader
