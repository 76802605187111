import YouTube from 'react-youtube';
import { Header, Segment, Tab } from 'semantic-ui-react';
import './VideoTab.css'

const VideoTab = () => {
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <Tab.Pane>
      <Segment.Group>
        <Segment>
          <Header>Juke Box Hero</Header>
          <div className="video-responsive">
            <YouTube videoId="W_TOsFvnmeQ" opts={opts} />
          </div>
        </Segment>
        <Segment>
        <Header>Puppies!!</Header>
        <div className="video-responsive">
            <YouTube videoId="_EEtmcl6Yy8" opts={opts} />
          </div>
        </Segment>
        <Segment>
        <Header>Chunk is almost as cute as me</Header>
        <div className="video-responsive">
            <YouTube videoId="KefJsQ39_XM" opts={opts} />
          </div>
        </Segment>
      </Segment.Group>
    </Tab.Pane>
  )
};

export default VideoTab;