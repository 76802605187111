import {Container, Header, List, Tab} from 'semantic-ui-react';

const HankTab = () => {
    return (
        <Tab.Pane>
            <Container textAlign="left">
                <Header size={'huge'}>NOT a ...</Header>
                <List bulleted>
                    <List.Item>Burmese Mountain Dog</List.Item>
                    <List.Item>Béarnaise Mountain Dog</List.Item>
                    <List.Item>Saint Bernard</List.Item>
                    <List.Item>Long haired Rottweiler</List.Item>
                    <List.Item>Border Collie</List.Item>
                    <List.Item>Australian Shepherd Dog</List.Item>
                    <List.Item>...</List.Item>
                </List>
            </Container>
        </Tab.Pane>
    );
}

export default HankTab;