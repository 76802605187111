import { Tab } from 'semantic-ui-react';
import Flyer from './flyer.jpg';

function WalkTab() {
    return (
      <Tab.Pane>
        <img src={Flyer} alt="Next walk" style={{ maxWidth: '100%', width: 'auto' }} />
      </Tab.Pane>
    );
  }
  
  export default WalkTab;